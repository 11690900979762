.event-row-carousel {
  width: 100%;
}
.event-row-carousel .slick-slider {
  width: 100%;
}

.event-row-carousel .slick-track {
  gap: 12px;
  display: flex;
}



.slick-track{
  align-self: flex-start;
  margin: revert
}