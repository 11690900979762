:root{
  ---primary_color:#FCAE1E
}
.darkmode-tag-container {
  background: transparent;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  border: 0.3px solid lightgray;
}
.lightmode-tag-container {
  background: white;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  border: 0.3px solid black;
}


.react-tagsinput-tag{
  background-color: var(---primary_color);
  border-radius: 2px;
  border: 1px solid var(---primary_color);
  color: #fff;
  font-size: 13px;

}