.responsiveTable tbody tr{
    height: 120px;
}
@media screen and (max-width: 40em){
.responsiveTable tbody tr {
    border: .3px solid rgb(214, 214, 214);
    padding: .25em;
    height: auto !important;
}
.responsiveTable td.pivoted {
    display: flex !important;
    align-items: center;
}
.responsiveTable td .tdBefore{
    color: white;
    font-size:14px;
}
}