.ticket_code {
  width: 65%;
  max-width: 65%;
  height: auto;
  display: flex;
  flex: 1;
}

#awesome-qrcode {
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 670px) {
  .ticket_code {
    width: 90%;
    max-width: 90%;
  }
}