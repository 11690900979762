.slick-slider,
.slick-initialized {
  width: 100%;
}

.slick-slide{
  width: 221px;
}

.slick-arrow {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-arrow.slick-prev {
  /* display: none !important; */
  position: absolute;
  /* right: 9px; */
  left: 10px;
  height: 70px;
  width: 45px;
  border-radius: 15px;
  /* background-color: red; */
  z-index: 1;
}

.slick-arrow.slick-next {
  position: absolute;
  right: 9px;
  height: 70px;
  width: 45px;
  border-radius: 15px;
}

/* .slick-arrow.slick-next:hover {
  background: linear-gradient(
    270deg,
    rgba(70, 69, 69, 0.226),
    rgba(126, 63, 11, 0.877)
  );
} */
.slick-arrow.slick-next::before{
  color: #FCAE1E;
  font-size:22px;
}
.slick-arrow.slick-prev::before{
  color: #FCAE1E;
  font-size:22px;
}
.slick-next.slick-disabled:before {
  opacity:1;
}