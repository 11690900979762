.categories-row-carousel {
    width: 100%;
  }
  .categories-row-carousel .slick-slider {
    width: 100%;
  }
  
  .categories-row-carousel .slick-track {
    gap: 22px;
    display: flex;
  }
  
  .slick-slide {
    width: auto !important;
    margin-right: 12px;
    display: flex !important;
  }
  
  .slick-track{
    align-self: flex-start;
    margin: revert
  }