.event-row-carousel {
    width: 100%;
  }
  .event-row-carousel .slick-slider {
    width: 100%;
  }
  
  .event-row-carousel .slick-track {
    gap: 12px;
    display: flex;
  }
  .event-row-carousel .slick-arrow.slick-prev {
    display: block !important;
  }