.dateTimeInput::-webkit-calendar-picker-indicator {
    filter: invert(70%); /* Invert the color to change it */
  }
  
  /* Additional styles for cross-browser compatibility */
  .dateTimeInput::-webkit-clear-button {
    filter: invert(70%); /* Invert the color to change it */
  }

  .dateTimeInput::-ms-clear {
    color: blue; 
  }