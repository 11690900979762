.Dropdown-root {
  width: 100%;
}
.Dropdown-control {
  width: 100%;
  padding: 13px;
  background: none;
  font-size: 13px;
  border: 1.4px solid rgb(193 193 193 / 46%);
  border-radius: 5px;
  color: #ffff;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Dropdown-arrow {
  top: inherit;
}
.Dropdown-menu {
  background-color: #121212;
  border: 1.4px solid rgb(193 193 193 / 46%);
}
.Dropdown-option {
  color: #ffff;
  font-size: 13px;
}
