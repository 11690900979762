.panel-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1f2326 !important;
  width: 70%;
  flex-direction: row;
  align-self: center;
  margin: auto;
}
/* .EZDrawer .EZDrawer__container {
  z-index: 1;
  padding: 0px 12px;
  transition-duration: 500ms;
  left: 0px;
  right: 0px;
  transform: translate3d(0px, 100%, 0px);
  width: 100%;
  height: auto;
  bottom: 130px !important;
  background: rgb(31, 35, 38);
} */

.pure-modal .close{
  background-color: #FCAE1E;
  color:#ffff ;
  display:flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 780px) {
  .panel-body {
    display: block;
    height: 100%;
    background: #1f2326;
    width: 100%;
    align-self: center !important;
    margin: auto !important;
    padding: 0px !important;
  }
  .pure-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #1f2326 !important ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .EZDrawer .EZDrawer__overlay {
    display: none;
  }
  
}
