#reader {
    width: 320px;
    height: 320px;
}

#reader~div {
    display: none;
}

#reader__scan_region {
    width: 100%;
    height: 100%;
}

#reader__scan_region img {
    background-color: #fcae1e;
    padding: 12px;
}

#reader__dashboard {
    background-color: #121212;
}

#reader__dashboard_section_csr {
    display: flex !important;
    justify-content: space-evenly;
}

#html5-qrcode-button-camera-permission {
    background-color: #fcae1e;
    margin-top: 30px;
    margin-bottom: 23px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: rgb(222, 220, 220);
}

#html5-qrcode-button-camera-start {
    background-color: #fcae1e;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: rgb(222, 220, 220);
}

#html5-qrcode-button-camera-stop {
    background-color: red;
    padding: 10px;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: rgb(222, 220, 220);
}

#html5-qrcode-anchor-scan-type-change {
    color: white;
}

/* New Styles */
qr-reader {
    width: 430px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
}

.qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
    .qr-reader {
        width: 100%;
    }
}