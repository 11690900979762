.carousel {
    width: 100%;
  }
  .carousel .slick-slider {
    width: 100%;
  }
  
  .carousel .slick-track {
    gap: 12px;
    display: flex;
    justify-content: flex-start;
width: 100% !important;
  }
  
  .carousel .slick-slide{
    width: 250px !important;
  }
  
  

  @media only screen and (max-width:780px) {
    .carousel .slick-slide{
        width: 400px !important;
       }
  }